import React , { Suspense } from 'react';
import './index.css';
import ReactDOM from 'react-dom/client';
import { Route, Router,Switch } from "wouter";
import { pathToRegexp } from "path-to-regexp";
import makeCachedMatcher from "wouter/matcher";
const App =  React.lazy(() => import('./App'));
const Nft =  React.lazy(() => import('./Nft'));
const Revenue =  React.lazy(() => import('./Revenue'));
const Tokenomics =  React.lazy(() => import('./Tokenomics'));


/*
 * This function specifies how strings like /app/:users/:items* are
 * transformed into regular expressions.
 *
 * Note: it is just a wrapper around `pathToRegexp`, which uses a
 * slightly different convention of returning the array of keys.
 *
 * @param {string} path — a path like "/:foo/:bar"
 * @return {{ keys: [], regexp: RegExp }}
 */
const convertPathToRegexp = (path) => {
  let keys = [];

  // we use original pathToRegexp package here with keys
  const regexp = pathToRegexp(path, keys, { strict: true });
  console.log(regexp)
  console.log(keys)
  return { keys, regexp };
};

const customMatcher = makeCachedMatcher(convertPathToRegexp);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Suspense fallback={
        <div className="flex items-center justify-center w-screen h-screen text-white text-md md:text-xl">
             <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-gray-900" style={{ borderTopColor: 'currentcolor', borderBottomColor: 'transparent' }}></div>
        </div>
    }>
      <Router matcher={customMatcher}>
        {/* at the moment wouter doesn't support inline regexps, but path-to-regexp does! */}
        <Switch>
          <Route path="/" component={App}></Route>
          <Route path="/revenue" component={Revenue}></Route>
          <Route path="/nfts/:rest*" component={Nft} />
          <Route path="/nfts/:rest*" component={Nft} />
          <Route>404, Not Found!</Route>
        </Switch>
      </Router>
    </Suspense>
  </React.StrictMode>
);
